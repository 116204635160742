<template>
  <div class="position-relative" style="height: calc(100vh - 10rem)">
    <div
      :class="[
        { expanded: true },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
      ]"
      class="main-menu menu-shadow shadow overflow-hidden"
      style="height: calc(100vh - 10rem); z-index: 996"
    >
      <div class="my-1 mx-2 cursor-pointer" @click="currentMenu = null">
        <page-title></page-title>
      </div>

      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="{ maxScrollbarLength: 60, wheelPropagation: true }"
        class="main-menu-content scroll-area overflow-hidden"
        tagname="ul"
        @ps-scroll-y="
          (evt) => {
            shallShadowBottom = evt.srcElement.scrollTop > 0;
          }
        "
      >
        <vertical-nav-menu-items
          v-if="menuTree"
          :items="menuTree"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
    </div>
    <div
      style="
        width: calc(100% - 260px);
        height: calc(100vh - 10rem);
        margin-left: 260px;
        position: relative;
      "
    >
      <div
        v-if="currentMenu"
        class="h-100 w-100 position-absolute"
        style="z-index: 2"
      >
        <template v-if="currentMenu.iframe">
          <b-embed
            id="jsf-iframe"
            :src="currentMenu.iframe"
            allow-cross-origin
            allowfullscreen
            aspect="4by3"
            loading="lazy"
            type="iframe"
          ></b-embed>
          <div
            class="position-absolute text-right rounded px-1"
            style="
              bottom: 0px;
              right: 0px;
              height: 2rem;
              background: rgba(0, 0, 0, 0.4);
            "
          >
            <span class="text-light" style="line-height: 2rem">{{
              currentMenu.iframe
            }}</span>
          </div>
        </template>

        <template v-else>
          <div
            v-if="currentMenu.component_key === 'panel-group'"
            style="padding: 6.5rem 2rem 0 2rem"
          >
            <panel-group
              v-if="currentMenu.group_uid"
              :group-uid="currentMenu.group_uid"
              :show-title="false"
            ></panel-group>
          </div>

          <component v-else :is="currentMenu.component_key"></component>
        </template>
      </div>

      <div class="h-100 w-100 position-absolute">
        <div
          v-show="!currentMenu"
          id="inside-menu-container"
          class="h-100 w-100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle";
import VerticalNavMenuItems from "@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAppConfig from "@core/app-config/useAppConfig";
import useVerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { nextTick, onMounted, provide, ref, watch } from "@vue/composition-api";
import Embed from "@/views/panel-group/components/embed/View";
import axios from "axios";
import PanelGroup from "@/views/panel-group";
import G6 from "@antv/g6";
import router from "@/router";

export default {
  name: "OperationsLayout",
  components: {
    PageTitle,
    Embed,
    VerticalNavMenuItems,
    VuePerfectScrollbar,
    PanelGroup,
    FaultInformation: () => import("./components/FaultInformation"),
    Operation: () => import("./components/Operation"),
    OperationMode: () => import("./components/OperationMode"),
    OperationSituation: () => import("./components/OperationSituation"),
    FaultTreatment: () => import("./components/FaultTreatment"),
    IntelligentDiagnosis: () => import("./components/IntelligentDiagnosis"),
    EquipmentMaintenance: () => import("./components/EquipmentMaintenance"),
    Inspection: () => import("./components/Inspection"),
    IntegratedDispatching: () => import("./components/IntegratedDispatching"),
    MultiTerminalRepair: () => import("./components/MultiTerminalRepair"),
    ServiceWork: () => import("./components/ServiceWork"),
    AlarmInformation: () => import("./components/AlarmInformation"),
    AlarmLogging: () => import("./components/AlarmLogging"),
    AlarmProcessing: () => import("./components/AlarmProcessing"),
    AutomaticFaceRecognition: () =>
      import("./components/AutomaticFaceRecognition"),
    VideoInspection: () => import("./components/VideoInspection"),
    VideoLinkage: () => import("./components/VideoLinkage"),
    BigScreen: () => import("./components/BigScreen"),
    EmergencyPreplan: () => import("./components/EmergencyPreplan"),
    SystemNetworkTopology: () => import("./components/SystemNetworkTopology"),
    InspectionList: () => import("./components/InspectionList.vue"),
    RepairList: () => import("./components/repair-list/RepairList.vue"),
  },
  props: ["operation"],
  setup(props) {
    const { skin } = useAppConfig();
    const shallShadowBottom = ref(false);
    const currentMenu = ref(null);
    const { isMouseHovered } = useVerticalNavMenu(props);
    const menuTree = ref([]);
    const hideMenu = ref(false);
    provide("isMouseHovered", isMouseHovered);

    //监听路由变化
    watch(
      () => store.state.menus.inner_menu_current,
      (current) => {
        //判断用户是否有权限
        // let permission_page = "";
				//
        // if (current.group_uuid) {
        //   permission_page = "panel-group/" + current.group_uuid;
        // } else {
        //   permission_page =
        //     current.iframe || current.href || current.component_key;
        // }
        // if (!permission_page || !checkPermission(permission_page)) {
        //   if (current) showToast(null, "异常：", "您没有权限访问此页面！");
        //   currentMenu.value = null;
        //   return;
        // }

        if (current.iframe) {
          //若链接不以http开始，则加上当前网址的http协议
          const location_protocol = window.location.protocol;
          if (current.iframe.slice(0, 4) !== "http") {
            current.iframe = location_protocol + "//" + current.iframe;
          }
          //对小程序链接单独处理
          if (current.iframe.indexOf("v4.qiyebox.com") > -1) {
            axios
              .get("https://v4.qiyebox.com/admin/user/info/e9/token")
              .then((res) => {
                const tenant_id = res.data.data.tenant_id;
                const access_token = res.data.data.access_token;
                let symbol = current.iframe.indexOf("?") > -1 ? "&" : "?";
                current.iframe +=
                  symbol +
                  "tenant_id=" +
                  tenant_id +
                  "&access_token=" +
                  access_token;
              });
          }
        }
        currentMenu.value = current;
        if (current.iframe) {
          load_iframe();
        } else if (current.iframe === "") {
          showToast(null, "错误：", "此链接为空！");
        }
      }
    );

    //判断用户是否有权限
    const checkPermission = (permission_page) => {
      const permission_type = router.currentRoute.name.replace(
        "project-jsf-",
        ""
      );
      const permission = permission_type + "___" + permission_page + "___view";
      const userPermission =
        store.state.jsf35.user_permissions ||
        JSON.parse(localStorage.getItem("jsf35UserPermission"));
      let hasPermission = false;
      if (userPermission && userPermission.length > 0) {
        for (const item of userPermission) {
          if (item === permission) {
            hasPermission = true;
            break;
          }
        }
      }
      return hasPermission;
    };

    const load_menu_tree = async (operationType, fontColor = "#d0d2d6") => {
      let menus = [];
      if (operationType) {
        const json_path = "/jsf35/iframe_pages/" + operationType + ".json";
        await fetch(json_path)
          .then((res) => res.json())
          .then((data) => {
            menus = data;
            console.log("menus", menus);
            data = {
              id: "root",
              title: "root",
              children: menus,
            };
            const container = document.getElementById("inside-menu-container");
            const width = container.scrollWidth;
            const height = container.scrollHeight || 500;
            const graph = new G6.TreeGraph({
              container: container,
              width,
              height,
              modes: {
                default: [
                  // {
                  //   type: "collapse-expand",
                  //   onChange: function onChange(item, collapsed) {
                  //     const data = item.get("model");
                  //     data.collapsed = collapsed;
                  //     return true;
                  //   },
                  // },
                  {
                    type: "click-select",
                    onClick: function onClick({ item }) {
                      const data = item.get("model");
                      if (data.children) {
                        showToast(null, "提示：", "该节点有子节点，无法跳转！");
                      } else {
                        store.commit(
                          "menus/UPDATE_MENU_INNER_MENU_CURRENT",
                          data
                        );
                      }
                      return true;
                    },
                  },
                  "drag-canvas",
                  // "zoom-canvas",
                ],
              },
              defaultNode: {
                size: 26,
                anchorPoints: [
                  [0, 0.5],
                  [1, 0.5],
                ],
              },
              defaultEdge: {
                type: "cubic-horizontal",
              },
              layout: {
                type: "mindmap",
                direction: "H",
                getHeight: () => {
                  return 16;
                },
                getWidth: () => {
                  return 16;
                },
                getVGap: () => {
                  return 10;
                },
                getHGap: () => {
                  return 100;
                },
                getSide: () => {
                  return "right";
                },
              },
            });

            let centerX = 0;
            graph.node(function (node) {
              return {
                label: node.title,
                labelCfg: {
                  position:
                    node.children && node.children.length > 0
                      ? "right"
                      : node.x > centerX
                      ? "right"
                      : "left",
                  offset: 5,
                  style: {
                    fill: fontColor,
                    fontSize: 15,
                    fontWeight: 500,
                    stroke: fontColor,
                    lineWidth: 0,
                  },
                },
              };
            });

            graph.data(data);
            graph.render();
            graph.fitView();

            if (typeof window !== "undefined")
              window.onresize = () => {
                if (!graph || graph.get("destroyed")) return;
                if (
                  !container ||
                  !container.scrollWidth ||
                  !container.scrollHeight
                )
                  return;
                graph.changeSize(container.scrollWidth, container.scrollHeight);
              };
          });
      }
      menuTree.value = menus;
    };
    const load_iframe = () => {
      showToast(null, "提示：", "页面加载中...");
      const postData = {
        accessToken: localStorage.getItem("accessToken"),
        project_id: 72,
      };
      nextTick(() => {
        const iframe_dom = document.getElementById("jsf-iframe");
        console.log("iframe_dom", iframe_dom);
        if (iframe_dom) {
          iframe_dom.onload = () => {
            iframe_dom.contentWindow.postMessage(postData, "*");
            console.log("已向iframe发送数据", postData);
            // showToast(null, '成功：', '页面已加载！');
          };
        }
      });
    };

    const hasThirdLevel = (menuItem) => {
      return (
        menuItem?.children &&
        menuItem.children.some(
          (item) => item?.children && item.children.length > 0
        )
      );
    };

    onMounted(() => {
      load_menu_tree(props.operation);
      hasThirdLevel();
    });

    return {
      skin,
      shallShadowBottom,
      currentMenu,
      menuTree,
      hasThirdLevel,
      hideMenu,
    };
  },
};
</script>

<style scoped>
.sub-nav-level-1,
.sub-nav-level-2,
.sub-nav-level-1,
.sub-nav-level-2,
.sub-nav-level-3 li {
  list-style: none;
}

.sub-nav-level-1 > li {
  margin-bottom: 14px;
}

.sub-nav-level-1 > span {
  font-weight: bold;
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}

.sub-nav-level-2 > a {
  font-weight: bold;
  font-size: smaller;
  color: gray;
  margin-bottom: 14px;
}

.sub-nav-level-3 > li {
  margin-bottom: 0.5rem;
}

.sub-nav-level-3 > a:hover {
  color: #1890ff;
}

.box {
  margin: 10px;
  column-count: 4;
  column-gap: 10px;
}

.item {
  margin-bottom: 10px;
}
</style>
